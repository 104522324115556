import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/index/logo_w.png';
import _imports_1 from '@/assets/images/ritIcon.png';
import _imports_2 from '@/assets/images/avatar.png';
import _imports_3 from '@/assets/index/lange.png';
const _hoisted_1 = {
  class: "headers"
};
const _hoisted_2 = {
  class: "max-width acea-row row-between-wrapper"
};
const _hoisted_3 = {
  class: "left acea-row row-left"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "rit acea-row row-right"
};
const _hoisted_6 = {
  class: "notice"
};
const _hoisted_7 = {
  key: 0,
  class: "avatar"
};
const _hoisted_8 = {
  key: 1,
  class: "buttons"
};
const _hoisted_9 = {
  class: "lange"
};
const _hoisted_10 = {
  class: "All_lange_show_dia"
};
const _hoisted_11 = {
  class: "lists"
};
const _hoisted_12 = ["onClick"];
const _hoisted_13 = {
  class: "let"
};
import { router } from "@/router";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { getNotices } from "@/http";
import { useUserStore } from "@/stores/user";
import enUS from "vant/es/locale/lang/en-US";
import frFR from "vant/es/locale/lang/fr-FR";
import enES from "vant/es/locale/lang/es-ES";
import itIT from "vant/es/locale/lang/it-IT";
import enTH from "vant/es/locale/lang/th-TH";
import { Locale } from "vant";
import { useRoute } from "vue-router";
export default {
  __name: 'Nvar',
  setup(__props) {
    const {
      t,
      locale
    } = useI18n();
    const userStore = useUserStore();
    const token = computed(() => userStore.token);
    const active = ref(0);
    const route = useRoute();
    const routerList = ref([{
      name: 'home',
      path: '/home'
    }, {
      name: 'participate_in_staking',
      path: '/liquidity'
    }, {
      name: 'News',
      path: '/news'
    }]);
    const initRute = () => {
      console.log(route);
      let show = false;
      routerList.value.forEach((item, index) => {
        if (item.path === route.path) {
          active.value = index;
          show = true;
        }
      });
      if (!show) {
        active.value = null;
      }
    };
    initRute();
    const openUrl = (item, index) => {
      router.push(item.path);
      active.value = index;
    };
    const jumpTo = path => {
      router.push(path);
      active.value = null;
    };
    const notices = ref('');
    const _getNotices = () => {
      notices.value = '';
      getNotices().then(({
        data
      }) => {
        data.data.forEach(item => {
          notices.value += item[{
            'en_US': 'name',
            'fr_FR': 'fr_FR_name',
            'es_ES': 'es_ES_name',
            'it_IT': 'it_IT_name',
            'vi': 'vt_VT_name',
            'en_TH': 'en_TH_name'
          }[userStore.lang]] + '  ';
        });
      });
    };
    _getNotices();
    const show = ref(false);
    const localeActions = computed(() => userStore.localeActions);
    const onSelect = item => {
      changeLang(valueLang.value);
      show.value = false;
    };
    const changeLang = lang => {
      locale.value = lang;
      const langMap = {
        'en_US': {
          value: enUS,
          key: 'en-US'
        },
        'fr_FR': {
          value: frFR,
          key: 'fr-FR'
        },
        'es_ES': {
          value: enES,
          key: 'es-ES'
        },
        'it_IT': {
          value: itIT,
          key: 'it-IT'
        },
        'en_TH': {
          value: enTH,
          key: 'en_TH'
        }
      };
      Locale.use(langMap[lang]['key'], langMap[lang]['value']);
      userStore.setLang(lang);
      window.localStorage.setItem('lang', lang);
    };
    const lang = computed(() => userStore.lang);
    console.log(lang);
    const valueLang = ref();
    valueLang.value = userStore.lang;
    const setIndex = item => {
      valueLang.value = item.value;
    };
    return (_ctx, _cache) => {
      const _component_van_notice_bar = _resolveComponent("van-notice-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routerList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["router", {
            'actives': index === active.value
          }]),
          key: index,
          onClick: $event => openUrl(item, index)
        }, _toDisplayString(_ctx.$t(item.name)), 11, _hoisted_4);
      }), 128))]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_van_notice_bar, {
        mode: "closeable",
        color: "#fff",
        "left-icon": "volume-o",
        background: "#081526",
        style: {
          "border-radius": "26px"
        },
        text: notices.value,
        onClick: _cache[0] || (_cache[0] = $event => jumpTo('/notices'))
      }, {
        "right-icon": _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("img", {
          src: _imports_1,
          width: "16",
          alt: ""
        }, null, -1)])),
        _: 1
      }, 8, ["text"])]), token.value ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = $event => jumpTo('/me')),
        src: _imports_2,
        alt: ""
      })])) : (_openBlock(), _createElementBlock("div", _hoisted_8, " 登录 ")), _createElementVNode("div", _hoisted_9, [_createElementVNode("img", {
        onClick: _cache[2] || (_cache[2] = $event => show.value = true),
        src: _imports_3,
        alt: ""
      })])])])]), _createVNode(_component_el_dialog, {
        modelValue: show.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => show.value = $event),
        title: _ctx.$t('langeSet'),
        width: "448",
        "align-center": "",
        center: "",
        style: {
          "border-radius": "16px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localeActions.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "acea-row row-between-wrapper",
            onClick: $event => setIndex(item),
            key: index
          }, [_createElementVNode("div", _hoisted_13, _toDisplayString(item.name), 1), _createElementVNode("div", {
            class: _normalizeClass(["rit acea-row row-center-wrapper", {
              'colors': valueLang.value === item.value
            }])
          }, [valueLang.value === item.value ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 0,
            name: "success",
            size: "20px",
            color: "#fff"
          })) : _createCommentVNode("", true)], 2)], 8, _hoisted_12);
        }), 128))]), _createElementVNode("div", {
          class: "isButtons",
          onClick: onSelect
        }, _toDisplayString(_ctx.$t('Sure')), 1)])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }
};