import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import Nvar from "@/components/Nvar/Nvar.vue";
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
export default {
  __name: 'routerView',
  setup(__props) {
    const route = useRoute();
    let routers = ref('');
    watch(route, newRoute => {
      console.log(newRoute);
      routers.value = newRoute.name;
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view", true);
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(Nvar), _createElementVNode("div", {
        class: _normalizeClass({
          'max-width': _unref(routers) !== 'liquidity'
        })
      }, [_createVNode(_component_router_view)], 2)], 64);
    };
  }
};